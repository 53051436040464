import * as React from "react";
import { graphql, Link, PageProps } from "gatsby";
import homeSettings from "settings/home-page.json";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { createPagesConfig } from "src/site-config";
import {
  getPageUrl,
  getSearchPageUrl,
  isInternalUrl,
  formatNumberToText,
} from "src/utils";
import Job, { getJobPageSlug } from "types/job";
import moment from "moment";
import SEO from "components/seo";
import Ad from "components/ad";
import SearchBar from "components/search-bar";
import ReactTyped from "react-typed";

/* #region Functions */
function getOtherLinksUrlTag(link: otherLinks, siteUrl: string) {
  let urlTag =
    link.target === "_blank"
      ? "a"
      : isInternalUrl(link.url, siteUrl)
      ? "Link"
      : "a";

  return urlTag;
}

function getOtherLinksUrl(link: otherLinks, urlTag: string) {
  switch (urlTag) {
    default:
    case "a":
      if (link.url.startsWith("http://"))
        return link.url.replace("http://", "https://");
      else if (link.url.startsWith("https://") || link.url.startsWith("://")) {
        return link.url;
      } else {
        return "https://" + link.url;
      }
    case "Link":
      if (link.url.startsWith("#")) {
        return link.url;
      } else {
        return getPageUrl(link.url);
      }
  }
}
/* #endregion */

/* #region Types */
interface otherLinks {
  title: string;
  url: string;
  target: string;
}

interface HomePageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        siteUrl: string;
        siteName: string;
        headline: string;
      };
    };

    allJob: {
      edges: [
        {
          node: Job;
        }
      ];
      totalCount: number;
    };

    localSearchJob: {
      publicIndexURL: string;
      publicStoreURL: string;
    };
  };

  pageContext: {
    limit: number;
    sortField: string;
    sortOrder: string;
  };
}
/* #endregion */

/* #region Variables */

/* #endregion */

const HomePage = ({ data, pageContext, uri }: HomePageProps) => {
  const { siteUrl, siteName, headline } = data.site.siteMetadata;
  const { publicIndexURL, publicStoreURL } = data.localSearchJob;
  const totalJobCount = data.allJob.totalCount;
  const recentJobs = data.allJob.edges;
  const jobsPerColumn = Math.floor(recentJobs.length / 3);

  return (
    <React.Fragment>
      <SEO
        customTitle
        title={headline ? `${siteName} - ${headline}` : siteName}
        canonicalUrl={siteUrl}
      />

      <section>
        {/* Search bar */}
        <div className="container-fluid p-3 pb-5 bg-secondary">
          <div className="container my-3">
            <div
              className="row d-flex align-items-center text-center"
              style={{ minHeight: 80 }}
            >
              <div className="col">
                <h3 className="text-center text-white m-0">
                  Procurando vaga de{" "}
                  <ReactTyped
                    strings={homeSettings.topSearchedJobs.map(
                      (j) => `<b class="text-warning">${j.searchTerm}</b>?`
                    )}
                    typeSpeed={40}
                    backSpeed={25}
                    backDelay={3000}
                    showCursor={false}
                    contentType="html"
                    loop
                  />
                </h3>
              </div>
            </div>
            <div className="row justify-content-center text-center">
              <div className="col-12 col-md-10 col-lg-8 col-xxl-6 text-center">
                <SearchBar
                  indexUrl={publicIndexURL}
                  storeUrl={publicStoreURL}
                  size="lg"
                  buttonLabel="Buscar"
                  placeholder="Pesquise por um cargo, área ou região"
                  hideIcon
                />
              </div>
            </div>
          </div>
        </div>
        {/* Tables */}
        <div className="container d-none">
          <div className="row justify-content-center">
            <div className="col-md-8 col-lg-4 pt-3">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="fw-bold text-center text-primary p-0"
                        colSpan={2}
                      >
                        {/* <Link to="#"> */}
                        <p
                          className="text-truncate m-0 p-2"
                          style={{ height: "100%", width: "100%" }}
                        >
                          EMPREGOS MAIS BUSCADOS
                        </p>
                        {/* </Link> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[0].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[0].title}
                          </p>
                        </Link>
                      </td>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[0].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[4].title}
                          </p>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[1].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[1].title}
                          </p>
                        </Link>
                      </td>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[5].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[5].title}
                          </p>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[2].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[2].title}
                          </p>
                        </Link>
                      </td>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[6].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[6].title}
                          </p>
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getSearchPageUrl(
                            homeSettings.topSearchedJobs[3].searchTerm
                          )}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            {homeSettings.topSearchedJobs[3].title}
                          </p>
                        </Link>
                      </td>
                      <td className="p-0">
                        <Link
                          className="link-dark"
                          to={getPageUrl(createPagesConfig.jobListPageSlug)}
                        >
                          <p
                            className="text-truncate m-0 p-2"
                            style={{ height: "100%", width: "100%" }}
                          >
                            Veja mais {formatNumberToText(totalJobCount)}
                          </p>
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-8 col-lg-4 pt-3">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr>
                      <th className="fw-bold text-center text-primary p-0">
                        {/* <Link to="#"> */}
                        <p
                          className="text-truncate m-0 p-2"
                          style={{ height: "100%", width: "100%" }}
                        >
                          VAGAS MAIS ACESSADAS
                        </p>
                        {/* </Link> */}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {homeSettings.topViewedJobs.map((j, i) => {
                      return (
                        <tr key={i}>
                          <td className="p-0">
                            <Link className="link-dark" to={j.url}>
                              <p
                                className="text-truncate m-0 p-2"
                                style={{ height: "100%", width: "100%" }}
                              >
                                {j.title}
                              </p>
                            </Link>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-8 col-lg-4 pt-3">
              <div className="table-responsive">
                <table
                  className="table table-bordered"
                  style={{ tableLayout: "fixed" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="fw-bold text-center text-primary p-0"
                        colSpan={2}
                      >
                        <p
                          className="text-truncate m-0 p-2"
                          style={{ height: "100%", width: "100%" }}
                        >
                          VEJA OUTROS CONTEÚDOS
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(4)].map((a, tr) => {
                      return (
                        <tr key={tr}>
                          {[...Array(2)].map((b, td) => {
                            const link = homeSettings.otherLinks[tr * 2 + td];
                            const urlTag = getOtherLinksUrlTag(link, siteUrl);

                            switch (urlTag) {
                              default:
                              case "a":
                                return (
                                  <td key={td} className="p-0">
                                    <a
                                      className="link-dark"
                                      href={getOtherLinksUrl(link, urlTag)}
                                      target={link.target}
                                    >
                                      <p
                                        className="text-truncate m-0 p-2"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                        }}
                                      >
                                        {link.title}
                                      </p>
                                    </a>
                                  </td>
                                );

                              case "Link":
                                return (
                                  <td key={td} className="p-0">
                                    <Link
                                      className="link-dark"
                                      to={getOtherLinksUrl(link, urlTag)}
                                    >
                                      <p
                                        className="text-truncate m-0 p-2"
                                        style={{
                                          height: "100%",
                                          width: "100%",
                                        }}
                                      >
                                        {link.title}
                                      </p>
                                    </Link>
                                  </td>
                                );
                            }
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        {/* Ads */}
        <div className="container">
          <Ad>
            <div
              className="text-center bg-warning d-flex justify-content-center align-items-center my-3"
              style={{ height: 220, width: "100%" }}
            >
              <h2 className="text-center text-white">
                Espaço para propaganda (Ads)
              </h2>
            </div>
          </Ad>
        </div>

        {/* Vagas mais recentes */}
        <div className="container py-5 mb-3">
          <div className="row">
            <div className="col">
              <h3 className="text-uppercase text-center text-primary">
                <Link to={getPageUrl(createPagesConfig.jobListPageSlug)}>
                  Vagas mais recentes{" "}
                  <FontAwesomeIcon icon={faChevronRight} className="ms-2" />
                </Link>
              </h3>
            </div>
          </div>
          <div className="row justify-content-center">
            {recentJobs.length >= 3 ? (
              [...Array(3)].map((a, col) => {
                return (
                  <div
                    key={col}
                    className="col-md-8 col-lg-4 align-self-center py-3"
                  >
                    <div className="d-grid gap-3">
                      {[...Array(jobsPerColumn)].map((b, i) => {
                        const job = recentJobs[col * jobsPerColumn + i]?.node;

                        return (
                          <div key={i} className="card border-light shadow-sm">
                            <div className="card-body jobListItem">
                              <h6 className="card-title text-clamp-line-2">
                                {job.title}
                              </h6>
                              <p className=" p-0 text-clamp-line-2">
                                {job.description?.substring(0, 200)}
                              </p>
                              <h6 className="text-nowrap text-truncate text-muted card-subtitle mb-2">
                                {moment(parseInt(job.publishDate ?? "")).format(
                                  "LLL"
                                )}
                              </h6>
                              <Link
                                to={getJobPageSlug(job)}
                                className="stretched-link"
                              ></Link>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-md-8 col-xl-6 align-self-center py-3">
                <div className="text-white bg-info border rounded border-0 p-3 p-lg-4">
                  <p className="text-center mb-0">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="fs-5 me-3"
                    />
                    Desculpe, não encontramos nenhuma vaga. &#128533;
                  </p>
                </div>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col text-center">
              <Link
                to={getPageUrl(createPagesConfig.jobListPageSlug)}
                className="btn btn-primary btn-lg"
              >
                VER VAGAS DE EMPREGO
              </Link>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default HomePage;

export const homePageQuery = graphql`
  query homePageQuery(
    $limit: Int!
    $sortField: JobFieldsEnum!
    $sortOrder: [SortOrderEnum]!
  ) {
    site {
      siteMetadata {
        siteUrl
        siteName
        headline
      }
    }

    allJob(sort: { fields: [$sortField], order: $sortOrder }, limit: $limit) {
      edges {
        node {
          uid
          title
          description
          publishDate
        }
      }
      totalCount
    }

    localSearchJob {
      publicIndexURL
      publicStoreURL
    }
  }
`;
