import siteConfig, { createPagesConfig } from "../site-config";
import { getSafeUrlPath, replaceObjDataInString } from "../utils";

export default Job;

export interface Job {
  uid: string;
  title: string;
  publishDate?: string;
  description?: string;
  roleTitle?: string;
  roleDescription?: string;
  hiringRegime?: string[];
  totalVacancies?: number;
  desiredSkills?: string;
  workType?: string[];
  city?: string;
  cityRegion?: string;
  benefits?: JobBenefit[];
  workHours?: string;
  salaryDescription?: string;
  additionalInfo?: string;
  userApplyQuestions?: JobQuestion[];
}

export interface JobBenefit {
  title: string;
}

export interface JobQuestion {
  fieldType: string;
  question: string;
}

export const jobSchema = `
type Job implements Node @dontInfer {
  id: String!
  uid: String!
  title: String!
  publishDate: String!
  description: String!
  roleTitle: String!
  roleDescription: String
  hiringRegime: [String]
  totalVacancies: Int
  desiredSkills: String
  workType: [String]
  city: String
  cityRegion: String
  benefits: [JobBenefit]
  workHours: String
  salaryDescription: String
  additionalInfo: String
  userApplyQuestions: [JobQuestion]
}

type JobBenefit @dontInfer {
  title: String!
}

type JobQuestion @dontInfer {
  fieldType: String!
  question: String!
}
`;

export function getJobPageSlug(job: Job, canonical = false) {
  let url = canonical
    ? createPagesConfig.canonicalJobPageSlug
    : createPagesConfig.jobPageSlug;

  url = replaceObjDataInString(url, job);

  return getSafeUrlPath(url);
}

export function getJobListPageSlug(pageId: number) {
  return pageId < 2
    ? createPagesConfig.jobListPageSlug
    : createPagesConfig.jobListPageSlug + pageId;
}

export function getSendResumePageSlug(job?: Job) {
  let url = createPagesConfig.sendResumePageSlug;

  url = getSafeUrlPath(url);

  if (job == null) {
    return url;
  }

  url += `?${getJobIdQueryParamName()}=${job.uid}`;

  return url;
}

export function getJobIdQueryParamName() {
  return createPagesConfig.jobIdQueryParamName;
}
